import * as action_types  from "./actionTypes";
import * as portfolioUploadApi from "../../api/portfolioUploadApi";

export function portfolioUploadSuccess(portfolio_upload_data) {
    console.log("PORTFOLIO UPLOAD RESPONSE: " + JSON.stringify(portfolio_upload_data));
    return { type: action_types.PORTFOLIO_UPLOAD_SUCCESS, portfolio_upload_data: portfolio_upload_data }
}

export function signedUriSuccess(portfolio_upload_data, idToken, key_name) {

    // return { type: action_types.PORTFOLIO_UPLOAD_SUCCESS, portfolio_upload_data: portfolio_upload_data }
    return function (dispatch) {
        return portfolioUploadApi.handlePortfolioUpload(portfolio_upload_data, idToken, key_name).then(portfolio_upload_data => {
            // console.log("UPLOAD RESPONSE: " + JSON.stringify(portfolio_upload_data));
            dispatch(portfolioUploadSuccess(portfolio_upload_data));
        }).catch(error => {
            throw error;
        })
    }
}

export function portfolioUpload(idToken, key_name) {
    return function (dispatch) {
        return portfolioUploadApi.handleSignedUriResponse(idToken, key_name).then(portfolio_upload_data => {
            // console.log("SIGNED URI: " + JSON.stringify(portfolio_upload_data));
            //debugger;
            dispatch(signedUriSuccess(portfolio_upload_data, idToken, key_name));
        }).catch(error => {
            throw error;
        })
    }
}