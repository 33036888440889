import React from 'react';
import logo from '../../images/logo-hannover-re.svg';

// import { PowerBIEmbed } from 'powerbi-client-react';
// import { models } from 'powerbi-client'

const Header = (props) => {
    return (
        <div>
            <header className="header">
                <img src={logo} className="brand-logo" alt="Hannover Re" />
                <div className="application-name"><h2>{props.application_title}</h2></div>
            </header>
            <hr />

            <div>
            </div>

        </div>

    )
}

export default Header;
