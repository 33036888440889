import * as action_types from "./actionTypes";
import * as activityMonitorApi from "../../api/activityMonitorApi";


export function getActivitiesOnSuccess(activity_monitor_data) {
    // console.log("Activity Monitor Data:");
    // console.log(activity_monitor_data);
    return { type: action_types.LOAD_ACTIVITY_MONITOR_DATA_SUCCESS, activity_monitor_data: activity_monitor_data};
}

export function getActivityMonitorData(idToken) {
    return function (dispatch) {
        // return { type: action_types.GET_ACTIVITY_MONITOR_DATA, activityMonitorData }
        return activityMonitorApi.getActivityMonitorData(idToken).then(activity_monitor_data => {
            dispatch(getActivitiesOnSuccess(activity_monitor_data));
        }).catch(error => {
            throw error;
        })
    }
}

export function errorFileDownloadSuccess(error_file_data) {
    // console.log("ERROR FILE DOWNLOAD RESPONSE: " + JSON.stringify(error_file_data));
    return { type: action_types.ERROR_FILE_DOWNLOAD_SUCCESS, error_file_data: error_file_data }
}

export function signedUriSuccess(error_file_data, idToken, key_name) {

    // return { type: action_types.PORTFOLIO_UPLOAD_SUCCESS, portfolio_upload_data: portfolio_upload_data }
    return function (dispatch) {
        return activityMonitorApi.handleErrorFileDownload(error_file_data, idToken, key_name).then(error_file_data => {
            // console.log("ERROR SIGNED URI RESPONSE: " + JSON.stringify(error_file_data));
            // debugger;
            dispatch(errorFileDownloadSuccess(error_file_data));
        }).catch(error => {
            throw error;
        })
    }
}

export function errorFileDownload(idToken, key_name) {
    return function (dispatch) {
        return activityMonitorApi.handleSignedUriResponse(idToken, key_name).then(error_download_data => {
            // console.log("SIGNED URI ERROR FILE: " + JSON.stringify(error_download_data)); // error_download_data.json()); //
            //debugger;
            dispatch(signedUriSuccess(error_download_data, idToken, key_name));
        }).catch(error => {
            throw error;
        })
    }
}