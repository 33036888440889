export default {
    user: {attributes: {profile: ""}},
    search_response: {
        results: []
    },
    activity_monitor_data: [],
    portfolio_data: [],
    calculations_data: [],
    portfolio_submission_date: "",
    calc_response: [],
    portfolio_upload_data: {},
    error_file_data: {headers: {etag: "", "x-amz-request-id": ""}},
    calc_download_data: {headers: {etag: "", "x-amz-request-id": ""}},
    calc_table_calc_download_data: {headers: {etag: "", "x-amz-request-id": ""}}
};