import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function calculationsDataReducer(state = initialStsate.calculations_data, action) {
    switch (action.type) {
        case action_types.GET_CALCULATIONS_DATA_SUCCESS:
            // console.log("CALCULATIONS ACTION: " + JSON.stringify(action.calculations_data));
            //debugger;
            return action.calculations_data;
        default:
            return state;
    }
}