import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as portfolioDataActions from "../../redux/actions/portfolioDataActions";

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

class PortfolioTable extends React.Component {
    state = {
        portfolio_data: []
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.user.attributes.profile.length != 0 && this.props.user !== prevProps.user) {
        // FIRST CALL WHEN USER HAS AUTHENTICATED
        const FORMAT = 'DD-MM-YYYY';
        let today = formatDate(new Date(), FORMAT);
        // console.log(today);
        this.props.actions.getPortfoliosData(this.props.user.signInUserSession.idToken.jwtToken, today).catch(error => {
          alert("Error getting portfolio data: " + error);
        });
      }
      // alert(prevProps.portfolio_submission_date + ' // ' + this.props.portfolio_submission_date);
      if (prevProps.portfolio_submission_date !== this.props.portfolio_submission_date) {
        this.props.actions.getPortfoliosData(this.props.user.signInUserSession.idToken.jwtToken, this.props.portfolio_submission_date).catch(error => {
          alert("Error getting portfolio data: " + error);
        });
      }
    }

    startCalcClickHandler = (file_name) => {
        // alert(file_name);
        alert("Calculation Started!");
        this.props.actions.startCalculation(this.props.user.signInUserSession.idToken.jwtToken, file_name).catch(error => {
          alert("Error getting portfolio data: " + error);
        });
        // this.props.startCalcClickHandler(file_name);
    }
    updateClickHandler = () => {
        // alert(this.props.portfolio_submission_date);
        this.props.actions.getPortfoliosData(this.props.user.signInUserSession.idToken.jwtToken, this.props.portfolio_submission_date)
        .then(res => {
          alert("Portfolio Data Updated!");
        })
        .catch(error => {
          alert("Error getting portfolio data: " + error);
        });       
        // this.props.updateClickHandler();
    }
    render() {
      return (
        <div>
          <div>
            <br />
            <h2>Portfolio Data</h2>
            <br />        
            <button className="btn" onClick={()=>{this.updateClickHandler()}} disabled={this.props.portfolio_submission_date == ''}>Update Data</button>
            <br />        
            <br />        
            <table className="table">
              <thead>
                <tr>
                  <th>Job-Name</th>
                  <th>Submission Date</th>
                  <th>Job-ID</th>
                  <th>Status</th>
                  <th>Calculation</th>
                  <th>Errors</th>
                </tr>
              </thead>
              <tbody>
              {this.props.portfolio_data.map((d) => (
                <tr className="active" key={d.job_id}>
                  <td>{d.display_name}</td>
                  <td>{d.created_at}</td>
                  <td>{d.job_id}</td>
                  <td>{d.status}</td>
                  <td><button className="btn" onClick={()=>{this.startCalcClickHandler(d.s3_key)}} disabled={d.status != 'finished'}>Start</button></td>
                  <td><button className="btn" onClick={()=>{this.startCalcClickHandler(d.s3_key)}} disabled='disabled'>Error</button></td>
                </tr>
              ))}
  
              </tbody>
            </table>
            <br />
            <br />
          </div>
        </div>
      )
    };
  }
  
  function mapStateToProps(state) {
    //debugger;
    // console.log("PORTFOLIO RESULT: ");
    // console.log(state.portfolio_data);
    return {
        user: state.user,
        portfolio_submission_date: state.portfolio_submission_date,
        portfolio_data: state.portfolio_data
    };
  }
  
  function mapDispatchToProps(dispatch) {
      return {
          actions: bindActionCreators(portfolioDataActions, dispatch)
      };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PortfolioTable);