import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function downloadCalcFileReducer(state = initialStsate.calc_download_data, action) {
    //debugger;
    switch (action.type) {
        case action_types.CALC_FILE_DOWNLOAD_SUCCESS:
            return action.calc_download_data;
        default:
            return state;
    }
}