import * as action_types  from "./actionTypes";
import * as portfolioDataApi from "../../api/portfolioDataApi";
import * as startCalculationApi from "../../api/startCalculationsApi";

export function startCalcSuccess(calc_response) {
    // console.log("START CALC RESPONSE: " + calc_response);
    return { type: action_types.START_CALCULATIONS_SUCCESS, calc_response: calc_response }
}

export function startCalculation(idToken, portfolio_file_name) {
    return function (dispatch) {
        return startCalculationApi.startCalculation(idToken, portfolio_file_name).then(calc_response => {
            // console.log("API CALL START CALCULATION RESPONSE: " + JSON.stringify(calc_response))
            //debugger;
            dispatch(startCalcSuccess(calc_response));
        }).catch(error => {
            throw error;
        })
    }
}
export function getPortfoliosDataSuccess(portfolio_data) {
    // console.log("PORTFOLIO RESPONSE: " + portfolio_data);
    return { type: action_types.GET_PORTFOLIO_DATA_SUCCESS, portfolio_data: portfolio_data }
}

export function getPortfoliosData(idToken, day_query) {
    return function (dispatch) {
        return portfolioDataApi.getPortfoliosData(idToken, day_query).then(portfolio_data => {
            // console.log("API CALL PORTFOLIO RESPONSE: " + JSON.stringify(portfolio_data))
            //debugger;
            dispatch(getPortfoliosDataSuccess(portfolio_data));
        }).catch(error => {
            throw error;
        })
    }
}