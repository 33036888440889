import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { properties } from '../config.js';
const baseUrl = properties.fe_content_api + "/fe/get_search_results";
const getSignedUrl = properties.fe_content_api + "/fe/get_calc_uri";


// const idToken = "eyJraWQiOiJXQUpzZ1dnRkpNNHpueXJpRGV5YTRMTFdRTElJN1I5Y2lDaHdENkhacnVjPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiLTZnb2ZVRThlLURXVl9OTVdHZlBmdyIsInN1YiI6IjM3OTUxYTFjLTQ5MjEtNGUwYy05MTQ1LWYwODc1NTczZmU4YiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LWNlbnRyYWwtMV9VMU1vT0hIZXBfb2t0YWRldiJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX1UxTW9PSEhlcCIsImNvZ25pdG86dXNlcm5hbWUiOiJva3RhZGV2X2V4dGVybmFsLnN0ZWZhbi5zdWhyZXJAaGFubm92ZXItcmUuY29tIiwiYXVkIjoiNXZkMmM1N3NnbmtlMXBpMXY1YW9tY3RrbG4iLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJleHRlcm5hbC5TdGVmYW4uU3VocmVyQGhhbm5vdmVyLXJlLmNvbSIsInByb3ZpZGVyTmFtZSI6Im9rdGFkZXYiLCJwcm92aWRlclR5cGUiOiJTQU1MIiwiaXNzdWVyIjoiaHR0cDpcL1wvd3d3Lm9rdGEuY29tXC9leGs0c2ZxZHVtVk5zNWhXdzBpNyIsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNjI0MzUxOTE0ODgyIn1dLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYyNDM2NjM3MiwiZXhwIjoxNjI0NTI2MzI1LCJpYXQiOjE2MjQ1MjI3MjUsImVtYWlsIjoiZXh0ZXJuYWwuU3RlZmFuLlN1aHJlckBoYW5ub3Zlci1yZS5jb20ifQ.GHLTg65pnUP7XpyvqfXZzHPAcdb9p7Hcb0Chdu-Rud7RXa6U03eQ8k7MD-B7qXcpwlFloafGyWDmYbZVVAi4agADaR7xmcIrGLZqo77cPEoHXoqn28xM53nPVgvmYoti3VCsbOdqJ59Y41A5q27IDrIWJ0nxaHYrmQkH-1buvUHSWpcm3c_ABgSSkIH1R7_zJ5C56Y3k7CKbPwfThs1b_jm2Ln3IT3H6k-p8R5CC_Geaci3POU5pFydinp9FFxhSQDT3SKr0US8G7FkNI-0eJFGsvm9GbG4f-aRxuYaHbAlumHqpxETlCMHUD5Cll0jHGLKQXwL6S22lg79KNtVUWA";

export function getMainSearchData(idToken, query_str) {
    // alert(idToken);
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        },
        body:  JSON.stringify({
            "search_str": query_str 
          })
    })
    .then(handleResponse)
    .catch(handleError)
}

export function getMainSearchSignedDownloadUri(idToken, file_name) {
    return fetch(getSignedUrl, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        },
        body:  JSON.stringify({
            "key_name": file_name 
          })
    })
    // UPLOAD FILE HERE
    .then(handleResponse) // handleResponse
    .catch(handleError)
}

export function handleCalcFileDownload(calc_download_data, idToken, file_name) {
    // console.log("HANDLE CALC DOWNLOAD RESPONSE:" + JSON.stringify(calc_download_data));
    //debugger;
    /*
    return fetch(error_file_data, {
        method: "GET"
    })
    */
   return axios.get(calc_download_data)
    // DOWNLOAD FILE HERE
    .then(handleResponse) // handleResponse
    .catch(handleError)
}