import React from "react";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/de';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as setPortfolioSubmissionDateAction from "../../redux/actions/setPortfolioSubmissionDateAction";

class PortfolioDatePicker extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    // alert(this.props.portfolio_submission_date);
    if (this.props.portfolio_submission_date === "") {
      const FORMAT = 'DD-MM-YYYY';
      let iso_date = formatDate(new Date(), FORMAT);
      //let iso_date_array = day.split('/');
      //let iso_date = `${iso_date_array[1]}-${iso_date_array[0]}-${iso_date_array[2]}`
      this.props.dispatch(setPortfolioSubmissionDateAction.setPortfolioSubmissionDateAction(iso_date));      
    }
    /*
    if (this.props.user.attributes.profile.length != 0 && this.props.user !== prevProps.user) {
      // FIRST CALL WHEN USER HAS AUTHENTICATED
      const FORMAT = 'DD-MM-YYYY';
      let today = formatDate(new Date(), FORMAT);

      this.props.actions.getCalculationsData(this.props.user.signInUserSession.idToken.jwtToken, today).catch(error => {
        alert("Error getting calculations data: " + error);
      });
    }      

    if (prevProps.portfolio_submission_date !== this.props.portfolio_submission_date) {
      this.props.actions.getCalculationsData(this.props.user.signInUserSession.idToken.jwtToken, this.props.portfolio_submission_date).catch(error => {
        alert("Error getting calculations data: " + error);
      });     
    }
    */
  }  
  componentDidMountTEST() {
      const FORMAT = 'DD-MM-YYYY';
      let day = formatDate(new Date(), FORMAT);
      let iso_date_array = day.split('/');
      let iso_date = `${iso_date_array[1]}-${iso_date_array[0]}-${iso_date_array[2]}`
      this.props.dispatch(setPortfolioSubmissionDateAction.setPortfolioSubmissionDateAction(iso_date));
  }
  sendData = (d) => {
      let iso_date_array = d.split('/');
      let iso_date = `${iso_date_array[2]}-${iso_date_array[0]}-${iso_date_array[1]}`
      //alert(iso_date);

      this.props.dispatch(setPortfolioSubmissionDateAction.setPortfolioSubmissionDateAction(iso_date));
    // this.props.parentDateChangedHandler(d);
  };
  render() {
    const FORMAT = 'DD-MM-YYYY';
    return (
      <div>
        <div>
          <h2>Portfolio Submissions</h2>
        </div>
        <div>
          Choose submission day: <DayPickerInput

              formatDate={formatDate}
              format={FORMAT}
              parseDate={parseDate}
              placeholder={`${formatDate(new Date(), FORMAT)}`}          
              //placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}

              onDayChange={day => {console.log(formatDate(day)); this.sendData(formatDate(day))}} 
            />
        </div>
        <br />
        <br />
        <hr></hr>
      </div>
    )}
};

function mapStateToProps(state) {
    // debugger;
    //console.log("SEARCH RESULT: ");
    //console.log(state.search_response);
    return {
        user: state.user,
        portfolio_submission_date: state.portfolio_submission_date
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(setPortfolioSubmissionDateAction, dispatch)
    };
}

export default connect(mapStateToProps)(PortfolioDatePicker);