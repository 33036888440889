import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { properties } from '../config.js';

const baseUrl = properties.fe_content_api + "/fe/activity_monitor_data";
const getSignedUrl = properties.fe_content_api + "/fe/get_error_download_uri";

export function getActivityMonitorData(idToken) {
    return fetch(baseUrl, {
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        }
    })
    .then(handleResponse)
    .catch(handleError)
}

export function handleSignedUriResponse(idToken, error_file_name) {
    // alert(idToken);
    // first get signed URI
    return fetch(getSignedUrl, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        },
        body:  JSON.stringify({
            "key_name": error_file_name 
          })
    })
    // UPLOAD FILE HERE
    .then(handleResponse) // handleResponse
    .catch(handleError)
}

export function handleErrorFileDownload(error_file_data, idToken, error_file_name) {
    //console.log("HANDLE ERROR DOWNLOAD RESPONSE:" + JSON.stringify(error_file_data));
    //debugger;
    /*
    return fetch(error_file_data, {
        method: "GET"
    })
    */
   return axios.get(error_file_data)
    // DOWNLOAD FILE HERE
    .then(handleResponse) // handleResponse
    .catch(handleError)
}