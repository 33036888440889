import { combineReducers } from "redux";
import activity_monitor_data from "./activityMonitorReducer";
import search_response from "./mainSearchReducer";
import user from "./authReducer";
import portfolio_data from "./portfolioDataReducer";
import portfolio_submission_date from "./setPortfolioSubmissionDateReducer";
import calculations_data from "./calculationsDataReducer";
import calc_response from "./startCalculationReducer";
import portfolio_upload_data from "./portfolioUploadReducer";
import error_file_data from "./downloadErrorFileReducer";
import calc_download_data from "./downloadCalcFileReducer";
import calc_table_calc_download_data from "./downloadCalcTableCalcFileReducer";

const rootReducer = combineReducers({
    user,
    activity_monitor_data,
    search_response,
    portfolio_data,
    calculations_data,
    portfolio_submission_date,
    calc_response,
    portfolio_upload_data,
    error_file_data,
    calc_download_data,
    calc_table_calc_download_data
});

export default rootReducer;