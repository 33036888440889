import React from 'react';

const Footer = (props) => {
    return (
        <div className="footer">
          <span>AccuCyb {props.accucyb_version}</span>
        </div>
    )
}

export default Footer;