import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function portfolioDataReducer(state = initialStsate.portfolio_data, action) {
    switch (action.type) {
        case action_types.GET_PORTFOLIO_DATA_SUCCESS:
            // console.log("PORTFOLIO ACTION: " + JSON.stringify(action.portfolio_data));
            //debugger;
            return action.portfolio_data;
        default:
            return state;
    }
}