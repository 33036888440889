import { handleResponse, handleError } from "./apiUtils";
import { properties } from '../config.js';

const baseUrl = properties.fe_content_api + "/fe/start_calculation";

export function startCalculation(idToken, portfolio_file_name) {
    // alert(idToken);
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        },
        body:  JSON.stringify({
            "file_name": portfolio_file_name
          })
    })
    .then(handleResponse)
    .catch(handleError)
}