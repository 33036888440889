export async function handleResponse(response) {
    // console.log("UTIL RESPONSE:" + JSON.stringify(response));
    //debugger;
    if (response.username) { return response };
    if (response.accessToken) { return response };
    if (response.headers['content-type'] === "binary/octet-stream") { return response }; // => error file download
    // if (response.ok && response.url) { return response };
    if (response.ok) { return response.json() };
    if (response.status === 204) { return response };
    if (response.status === 400) {
        const error = await response.text();
        throw new Error(error);
    }
    throw new Error("Network response was not OK!");
}
export function handleError(error) {
    console.error("API Error: " + error);
    throw error;
}

