import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import { properties } from '../config.js';

const baseUrl = properties.fe_content_api + "/fe/get_calculation";
const getSignedUrl = properties.fe_content_api + "/fe/get_calc_uri";

export function getCalculationsData(idToken, day_query) {
    // alert(idToken);
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        },
        body:  JSON.stringify({
            "day_query": day_query 
          })
    })
    .then(handleResponse)
    .catch(handleError)
}

export function getCalcTableSignedDownloadUri(idToken, file_name) {
    return fetch(getSignedUrl, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        },
        body:  JSON.stringify({
            "key_name": file_name 
          })
    })
    // UPLOAD FILE HERE
    .then(handleResponse) // handleResponse
    .catch(handleError)
}

export function handleCalcFileDownload(calc_download_data, idToken, file_name) {
    //console.log("HANDLE CALC TABLE DOWNLOAD RESPONSE:" + JSON.stringify(calc_download_data));
    //debugger;
    /*
    return fetch(error_file_data, {
        method: "GET"
    })
    */
   return axios.get(calc_download_data)
    // DOWNLOAD FILE HERE
    .then(handleResponse) // handleResponse
    .catch(handleError)
}