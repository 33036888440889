import * as action_types  from "./actionTypes";
import * as calculationsDataApi from "../../api/calculationsDataApi";

export function getCalculationsDataSuccess(calculations_data) {
    // console.log("CALCULATIONS RESPONSE: " + calculations_data);
    return { type: action_types.GET_CALCULATIONS_DATA_SUCCESS, calculations_data: calculations_data }
}

export function getCalculationsData(idToken, day_query) {
    return function (dispatch) {
        return calculationsDataApi.getCalculationsData(idToken, day_query).then(calculations_data => {
            //console.log("API CALL CALCULATIONS RESPONSE: " + JSON.stringify(calculations_data))
            //debugger;
            dispatch(getCalculationsDataSuccess(calculations_data));
        }).catch(error => {
            throw error;
        })
    }
}

export function calcFileDownloadSuccess(calc_table_calc_download_data) {
    // console.log("CALC FILE DOWNLOAD RESPONSE: " + JSON.stringify(calc_table_calc_download_data));
    return { type: action_types.CALC_TABLE_CALC_FILE_DOWNLOAD_SUCCESS, calc_table_calc_download_data: calc_table_calc_download_data }
}

export function signedUriSuccess(calc_table_calc_download_data, idToken, file_name) {

    // return { type: action_types.PORTFOLIO_UPLOAD_SUCCESS, portfolio_upload_data: portfolio_upload_data }
    return function (dispatch) {
        return calculationsDataApi.handleCalcFileDownload(calc_table_calc_download_data, idToken, file_name).then(calc_table_calc_download_data => {
            // console.log("ERROR SIGNED URI RESPONSE: " + JSON.stringify(error_file_data));
            // debugger;
            dispatch(calcFileDownloadSuccess(calc_table_calc_download_data));
        }).catch(error => {
            throw error;
        })
    }
}

export function handleCalcTableCalcDownload(idToken, file_name) {
    return function (dispatch) {
        return calculationsDataApi.getCalcTableSignedDownloadUri(idToken, file_name).then(calc_table_calc_download_data => {
            // console.log("API CALL SEARCH RESPONSE: " + JSON.stringify(search_response))
            //debugger;
            dispatch(signedUriSuccess(calc_table_calc_download_data, idToken, file_name));
        }).catch(error => {
            throw error;
        })
    }
}