import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function downloadErrorFileReducer(state = initialStsate.error_file_data, action) {
    //debugger;
    switch (action.type) {
        case action_types.ERROR_FILE_DOWNLOAD_SUCCESS:
            return action.error_file_data;          
        default:
            return state;
    }
}