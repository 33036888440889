import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function authReducer(state, action) {//authReducer(state = initialStsate.user, action) {
    switch (action.type) {
        case action_types.AUTH_SUCCESS:
            console.log("ACTION: ");
            console.log(action.user);
            let user_data = action.user;
            //let test = ["TOP_F_AccuCyb_Enhanced"];
            //let test = ["TOP_F_AccuCyb_User"];
            //let test = ["TOP_F_AccuCyb_Enhanced", "TOP_F_AccuCyb_User"];

            //user_data.attributes.profile = "[TOP_F_AccuCyb_Enhanced, TOP_F_AccuCyb_User]";

            //prof.push(test);
            //console.log(prof);
            //alert(Array.isArray(test));
            //user_data.attributes.profile = test;
            //console.log(user_data);          
            //try {

            //user_data.attributes.profile = user_data.attributes.profile.replace('[', '').replace(']', '').replace(' ', '').split(',');

            /*
            if (!Array.isArray(user_data.attributes.profile)) {
                console.log("Is No Array:");
                let profile_array = [];
                profile_array.push(user_data.attributes.profile);
                console.log(profile_array);
                user_data.attributes.profile = profile_array;
            } else {
                console.log("Is Array:");
                let prof = [];
                // let user_data.attributes.profile = ["[TOP_F_AccuCyb_Enhanced, TOP_F_AccuCyb_User]"];
                user_data.attributes.profile.forEach(x => {
                    const a = x.replace('[', '').replace(']', '').replace(' ', '').split(',');
                    a.forEach(y => prof.push(y));
                });
                console.log(prof);
                user_data.attributes.profile = prof;
            }
            */
            //} catch {
            //    user_data.attributes.profile = [];
            //}
            // console.log(user_data);
            // debugger;
            return user_data; //[...state, { ...action.user }];
        case action_types.GET_JWT_SUCCESS:
            // console.log("ACTION: " + JSON.stringify(action.user));
            // debugger;
            // alert("ACTION: " + JSON.stringify(action.user));
            const new_user = state;
            new_user.signInUserSession.idToken = action.user.idToken;
            new_user.signInUserSession.refreshToken = action.user.refreshToken;
            new_user.signInUserSession.accessToken = action.user.accessToken;
            //debugger;
            //return Object.assign({}, state, {user: new_user});
            return new_user; //{...state, { ...action.jwt_token }};
        default:
            if (state) {
                return state;
            } else {
                return initialStsate.user;
            }
    }
}