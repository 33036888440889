const properties = {
    title: 'AccuCyb',
    redirectSignIn: 'https://accucyb-dev.hannover-re.com/',
    redirectSignOut: 'https://accucyb-dev.hannover-re.com/',
    cognitoDomain: 'accucyb-dev.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_U1MoOHHep',
    appClientId: '53d0mq49b825jv892dv78i7d4p',
    identityPoolId: 'eu-central-1:2d17fcb9-ce78-4916-ab8d-91877b7482a4',
    endpointName: "accucyb_ui",
    user_group: "eu-central-1_U1MoOHHep_oktadev",
    fe_content: "https://lambda.eu-central-1.amazonaws.com/2015-03-31/functions/accu-cyb-dev-fe_content/invocations",
    fe_content_api: "https://accucyb-api-dev.hannover-re.com",
    bucket: "accucyb-clamav-scanned-dev",
    power_bi_url: "https://app.powerbi.com/groups/9122bc44-20ed-47a3-9878-842d2262d70d/list/dashboards",
    power_bi_embedded: {
            authenticationMode: "ServicePrincipal",
            authorityUri: "https://login.microsoftonline.com/common/v2.0",
            scope: "https://analysis.windows.net/powerbi/api",
            apiUrl: "https://api.powerbi.com/",
            clientId: "2dcf5f50-712b-47e3-b913-cbfea9380992",
            workspaceId: "9122bc44-20ed-47a3-9878-842d2262d70d",
            reportId: "1f11d9d0-fc1b-43ed-a748-c9675e7cefe6",
            pbiUsername: "",
            pbiPassword: "",
            clientSecret: "5S8Lo4zSMubI5R._5h-SKW6J2~_A_vRIOy",
            tenantId: "815f40e5-50f9-45a8-ae03-40ad9b986f88"
    }
};

export { properties };