import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function setPortfolioSubmissionDateReducer(state = initialStsate.portfolio_submission_date, action) {
    switch (action.type) {
        case action_types.SET_PORTFOLIO_SUBMISSION_DATE:
            // console.log("ACTION: " + JSON.stringify(action));
            // debugger;
            return action.portfolio_submission_date; //[ ...state, {search_response: action.search_response }];
        default:
            return state;
    }
}