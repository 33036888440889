import React from "react";
import { connect } from "react-redux";
import * as activityMonitorActions from "../../redux/actions/activityMonitorActions";
import * as authActions from "../../redux/actions/authActions";
// import { bindActionCreators } from "redux";

const options = {hour: '2-digit', minute: '2-digit', second: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric' };

let download_etag = "-";
let download_filename = "-";
let timer_counter = 0;
let timer_interval = 15000;


class ActivityMonitorTable extends React.Component {
  intervalID;
  componentDidMount() {
    this.intervalID = setInterval(this.updateClickHandler.bind(this), timer_interval);
  }
  componentWillUnmount() {
    timer_counter = 0;
    clearInterval(this.intervalID);
  }
  updateClickHandler = () => {
    document.getElementById("btnUpdateActivityMonitor").textContent = 'Updating...';
    timer_counter += timer_interval/1000;
    //console.log(timer_counter);
    if (parseInt(timer_counter/60) === 10) {
    //if (parseInt(timer_counter/20) === 1) {
      console.log("GET NEW TOKEN!");
      this.props.authUser(true).catch(error => {
        alert("Error authenticating user: " + error);
      });
      timer_counter = 0;
    }
    // alert(this.props.calculations_data);
    //this.props.actions.getActivityMonitorData(this.props.user.signInUserSession.idToken.jwtToken).catch(error => {
    this.props.getActivityMonitorData(this.props.user.signInUserSession.idToken.jwtToken)
    .then(res => {
      // alert("Activity Monitor Data Updated!");
      document.getElementById("btnUpdateActivityMonitor").textContent = 'Update';
    })    
    .catch(error => {
        // alert("Error getting activity monitor data: " + error);
        console.log("Error getting activity monitor data: " + error);
        document.getElementById("btnUpdateActivityMonitor").textContent = 'Error Updating - Press To Re-Run';
      });       
    // this.props.updateClickHandler();
  };
  downloadData = (error_file_name) => {
    // alert(JSON.stringify(d));
    download_filename = error_file_name;
    //this.props.actions.errorFileDownload(this.props.user.signInUserSession.idToken.jwtToken, error_file_name)
    this.props.errorFileDownload(this.props.user.signInUserSession.idToken.jwtToken, error_file_name)
      .catch(error => {
        alert("Error getting activity error file data: " + error);
      });     
  };
  render() {
    return (
      <div>
        <h2>Activity Monitor</h2>
      <div>
        <br />
        <br />        
        <button className="btn" id="btnUpdateActivityMonitor" onClick={()=>{this.updateClickHandler()}} disabled={this.props.selected_date === ''}>Update Data</button>
        <br />        
        <br />         
        <br />        
        <table className="table">
          <thead>
              <tr>
                <th>Progress (%)</th>
                <th>Job-ID</th>
                <th>User</th>
                <th>Status</th>
                <th>Start</th>
                <th>Ende</th>
                <th>Errors</th>
              </tr>
            </thead>
            <tbody>
            {this.props.activity_monitor_data.map((t) => (
              <tr className="active" key={t.job_id}>
                  <td>
                    <div id="myProgress">
                      <div id="myBar" style={{width: t.percent_finished+"%"}}>{t.percent_finished}</div>

                    </div>
                  </td>
                  <td>{t.job_id}</td>
                  <td>{t.usr_name}</td>
                  <td>{t.task_status}</td>
                  <td>{new Date(t.created_at).toLocaleDateString('de-DE', options)}</td>
                  <td>{t.finished_at !== '' ? new Date(t.finished_at).toLocaleDateString('de-DE', options) : '-'}</td>
                  <td><button className="btn" onClick={()=>{this.downloadData(t.error_file)}} disabled={t.error_file === '-'}>Error File</button></td>
              </tr>
            ))}

            </tbody>
          </table>
        </div>
        <br />
        <br />            
      </div>
    )
  };
}

function mapStateToProps(state, ownProps) {
  //debugger;
  // console.log("ACTIVITY MONITOR RESULT: ");
  // console.log(state.activity_monitor_data); 
  // console.log("ACTIVITY MONITOR ERROR FILE: ");
  // console.log(state.error_file_data);
  // only create download link if new download was requested => click on download error
  if (state.error_file_data.headers['x-amz-request-id'] !== "" && state.error_file_data.headers['x-amz-request-id'] !== download_etag) {
    // console.log("x-amz-request-id: ");
    // console.log(state.error_file_data.headers['x-amz-request-id']);
    download_etag = state.error_file_data.headers['x-amz-request-id'];

    // create download link
    const element = document.createElement("a");
    const file = new Blob([state.error_file_data.data],{type: 'text/plain;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = download_filename;
    document.body.appendChild(element);
    element.click();
    // state.activity_monitor_data = {}
  }
  return {
    user: state.user,
    activity_monitor_data: state.activity_monitor_data,
    error_file_data: state.error_file_data
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityMonitorData: (jwtToken) => dispatch(activityMonitorActions.getActivityMonitorData(jwtToken)),
    errorFileDownload: (jwtToken, error_file_name) => dispatch(activityMonitorActions.errorFileDownload(jwtToken, error_file_name)),
    authUser: (refresh_flag) => dispatch(authActions.authenticateUser(refresh_flag))
  }
}

/*
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityMonitorActions, dispatch)
  };
}
*/

export default connect(mapStateToProps, mapDispatchToProps) (ActivityMonitorTable);

/*

      .then(res => {
        const element = document.createElement("a");
        const file = new Blob([this.props.error_file_data.data],{type: 'text/plain;charset=utf-8'});
        element.href = URL.createObjectURL(file);
        element.download = error_file_name;
        document.body.appendChild(element);
        element.click();         
      })

*/