import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as calculationsDataActions from "../../redux/actions/calculationsDataActions";
import { properties } from '../../config.js';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { resetPassword } from "amplify-redux-auth";

const power_bi_url = properties.power_bi_url;
let calc_download_filename = '-';
let calc_download_etag = '-';
let tmp_display_name = '-';
let main_btn_txt = 'Start Download';

class CalcTable extends React.Component {
  state = {
    calculations_data: [],
    buttonTxt: 'Start Download'

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user.attributes.profile.length != 0 && this.props.user !== prevProps.user) {
      // FIRST CALL WHEN USER HAS AUTHENTICATED
      const FORMAT = 'DD-MM-YYYY';
      let today = formatDate(new Date(), FORMAT);

      this.props.actions.getCalculationsData(this.props.user.signInUserSession.idToken.jwtToken, today).catch(error => {
        alert("Error getting calculations data: " + error);
      });
    }      

    if (prevProps.portfolio_submission_date !== this.props.portfolio_submission_date) {
      this.props.actions.getCalculationsData(this.props.user.signInUserSession.idToken.jwtToken, this.props.portfolio_submission_date).catch(error => {
        alert("Error getting calculations data: " + error);
      });     
    }
  }

  calcDownloadClickHandler = (file_name, display_name) => {
    //this.state.buttonTxt = 'Downloading...';
    document.getElementById(display_name).textContent = 'Downloading...';
    tmp_display_name = display_name;
    // alert(document.getElementById(display_name));

    calc_download_filename = file_name;
    // alert(file_name);
    this.props.actions.handleCalcTableCalcDownload(this.props.user.signInUserSession.idToken.jwtToken, file_name)
      .catch(error => {
        alert("Error getting calculations data: " + error);
        document.getElementById(display_name).textContent = this.state.buttonTxt;
      });
    // this.props.calcDownloadClickHandler(file_name);
  };
  updateClickHandler = () => {
    //alert(this.props.portfolio_submission_date);
    this.props.actions.getCalculationsData(this.props.user.signInUserSession.idToken.jwtToken, this.props.portfolio_submission_date)
    .then(res => {
      alert("Calculations Data Updated!");
    })
    .catch(error => {
          alert("Error getting calculations data: " + error);
        });       
    // this.props.updateClickHandler();
  }  
  render() {
    return (
      <div>
      <div>
      <br />
        <h2>Calculations Data</h2>
        <br />        
        <button className="btn" onClick={()=>{this.updateClickHandler()}} disabled={this.props.portfolio_submission_date == ''}>Update Data</button>
        <br />        
        <br />      
        <table className="table">
          <thead>
              <tr>
                <th>Calc-Name</th>
                <th>Submission Date</th>
                <th>Job-ID</th>
                <th>Results</th>
                <th>Report</th>
              </tr>
            </thead>
            <tbody>
            {this.props.calculations_data.map((t) => (
              <tr className="active" key={t.display_name}>
                  <td>{t.display_name}</td>
                  <td>{t.timestamp}</td>
                  <td>{t.job_id}</td>
                  <td><button id={t.display_name} className="btn" onClick={()=>{this.calcDownloadClickHandler(t.calc_file_name, t.display_name)}}>{this.state.buttonTxt}</button></td>
                  <td><button className="btn"><a target="_blank" href={power_bi_url}>Go</a></button></td>
              </tr>
            ))}

            </tbody>
          </table>
        </div>
      </div>
    )
  };
}

function mapStateToProps(state) {
  //debugger;
  // console.log("CALCULATIONS RESULT: ");
  // console.log(state.calculations_data);
  if (state.calc_table_calc_download_data.headers['x-amz-request-id'] !== "" && state.calc_table_calc_download_data.headers['x-amz-request-id'] !== calc_download_etag) {
    // console.log("x-amz-request-id: ");
    // console.log(state.error_file_data.headers['x-amz-request-id']);
    calc_download_etag = state.calc_table_calc_download_data.headers['x-amz-request-id'];

    // create download link
    const csv_name_array = calc_download_filename.split("_-_")
    let csv_name = csv_name_array[0];
    if (csv_name_array.length > 1) {
      csv_name = 'calc_' + csv_name_array[1] + "_-_" + csv_name_array[2];
    }

    const element = document.createElement("a");
    const file = new Blob([state.calc_table_calc_download_data.data],{type: 'text/csv;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = csv_name;
    document.body.appendChild(element);
    element.click();
    document.getElementById(tmp_display_name).textContent = main_btn_txt;
  }

  return {
      user: state.user,
      portfolio_submission_date: state.portfolio_submission_date,
      calculations_data: state.calculations_data,
      calc_table_calc_download_data: state.calc_table_calc_download_data
  };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(calculationsDataActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalcTable);