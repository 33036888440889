import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function mainSearchReducer(state = initialStsate.search_response, action) {
    switch (action.type) {
        case action_types.GET_MAIN_SEARCH_SUCCESS:
            // console.log("ACTION: " + JSON.stringify(action.search_response));
            //debugger;
            return action.search_response; //[ ...state, {search_response: action.search_response }];
        default:
            return state;
    }
}