export const GET_ACTIVITY_MONITOR_DATA = "GET_ACTIVITY_MONITOR_DATA";
export const LOAD_ACTIVITY_MONITOR_DATA_SUCCESS = "LOAD_ACTIVITY_MONITOR_DATA_SUCCESS";
export const GET_MAIN_SEARCH_SUCCESS = "GET_MAIN_SEARCH_SUCCESS";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const GET_USER_JWT_TOKEN = "GET_USER_JWT_TOKEN";
export const GET_JWT_SUCCESS = "GET_JWT_SUCCESS";
export const GET_PORTFOLIO_DATA = "GET_PORTFOLIO_DATA";
export const GET_PORTFOLIO_DATA_SUCCESS = "GET_PORTFOLIO_DATA_SUCCESS";
export const SET_PORTFOLIO_SUBMISSION_DATE = "SET_PORTFOLIO_SUBMISSION_DATE";
export const GET_CALCULATIONS_DATA_SUCCESS = "GET_CALCULATIONS_DATA_SUCCESS";
export const GET_CALCULATIONS_DATA = "GET_CALCULATIONS_DATA";
export const START_CALCULATIONS = "START_CALCULATIONS";
export const START_CALCULATIONS_SUCCESS = "START_CALCULATIONS_SUCCESS";
export const PORTFOLIO_START_CALCULATIONS = "PORTFOLIO_START_CALCULATIONS";
export const PORTFOLIO_START_CALCULATIONS_SUCCESS = "PORTFOLIO_START_CALCULATIONS_SUCCESS";
export const PORTFOLIO_UPLOAD_SUCCESS = "PORTFOLIO_UPLOAD_SUCCESS";
export const ERROR_FILE_DOWNLOAD_SUCCESS = "ERROR_FILE_DOWNLOAD_SUCCESS";
export const CALC_FILE_DOWNLOAD_SUCCESS = "CALC_FILE_DOWNLOAD_SUCCESS";
export const CALC_TABLE_CALC_FILE_DOWNLOAD_SUCCESS = "CALC_TABLE_CALC_FILE_DOWNLOAD_SUCCESS";