import React from 'react';
import styles from './css/hr-bootstrap-theme.css';
import '@aws-amplify/ui/dist/style.css';
import Amplify, { API, Auth } from 'aws-amplify';
//import { createTheme } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import { properties } from './config.js';
import { version } from './version.js';
import Footer from './components/common/Footer';
import ActivityMonitor from './components/activity_monitor/ActivityMonitor';
import PortfolioFileUpload from './components/portfolio_upload/PortfolioUpload';
import MainSearch from './components/main_search/MainSearch';
import PortfolioDatePicker from './components/portfolio_submission/PortfolioSubmission';
import PortfolioTable from './components/portfolio_data/PortfolioData';
import CalcTable from './components/calculation_data/CalculationData';
import Header from './components/common/Header';
import PowerBI from './components/powerbi/PowerBiEmbedded';
import { bindActionCreators } from "redux";
import * as authActions from "./redux/actions/authActions";
import { connect } from "react-redux";
//import Bootstrap from "./css/theme";
import { withAuthenticator, SignOut, Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings } from 'aws-amplify-react';
//import { withAuthenticator, Authenticator, AmplifyAuthenticator, AmplifySignOut, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
//import awsconfig from './aws-exports';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client'

import {
  Card, CardContent,
  Button, ButtonBase,
  Input, InputAdornment, IconButton, InputLabel,
  FormControl, FormHelperText,
  Typography, MuiThemeProvider,
} from '@material-ui/core';

import {
  Visibility, VisibilityOff,
} from '@material-ui/icons';

Amplify.configure({
  Auth: {
    //storage: MyStorage,
    domain: properties.cognitoDomain,
    userPoolWebClientId: properties.appClientId,
    identityPoolId: properties.identityPoolId,
    userPoolId: properties.userPoolId,
    region: 'eu-central-1',
    // Authorized scopes
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    // Callback URL
    redirectSignIn: properties.redirectSignIn,
    // Sign out URL
    redirectSignOut: properties.redirectSignOut,
    responseType: 'code',
    options: {
      // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
      AdvancedSecurityDataCollectionFlag: true
    },
    oauth: {
      // Domain name
      domain: properties.cognitoDomain,
      // Authorized scopes
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      // Callback URL
      redirectSignIn: properties.redirectSignIn,
      // Sign out URL
      redirectSignOut: properties.redirectSignOut,
      responseType: 'code',
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true
      }
    }
  },
  API: {
    endpoints: [
        {
            name: "fe_content",
            endpoint: properties.fe_content,
            service: "lambda",
            region: "eu-central-1"
        },
        {
            name: "fe_content_api",
            endpoint: properties.fe_content_api,
            region: "eu-central-1"
        },
        {
          name: "json_test",
          endpoint: 'https://jsonplaceholder.typicode.com',
          region: "eu-central-1"
        }        

    ]
  },
  Storage: {
    AWSS3: {
        bucket: properties.bucket, //REQUIRED -  Amazon S3 bucket name
        region: "eu-central-1", //OPTIONAL -  Amazon service region
    }
  }  
});
/*
const App = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
      return onAuthUIStateChange((nextAuthState, authData) => {
          setAuthState(nextAuthState);
          setUser(authData)
      });
  }, []);
  return authState === AuthState.SignedIn && user ? (
    <div className="App">
        <div>Hello, {user.username}</div>
        <AmplifySignOut />
    </div>
  ) : (
    <AmplifyAuthenticator>
            <AmplifySignUp
          slot="sign-up"
          formFields={[
            { type: "username" },
            { type: "password" },
            { type: "email" }
          ]}
        />
    </AmplifyAuthenticator>
);
}
*/

const config = Auth.configure();
const {
  domain,
  redirectSignIn,
  redirectSignOut,
  responseType } = config.oauth;

const clientId = config.userPoolWebClientId;
const url = 'https://' + domain + '/login?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId;

let current_session = Auth.currentSession();
//console.log("current_session: " + current_session);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      search_response: {},
      activityMonitorData: [],
      power_bi_token: ""
    };
  }
  componentDidMount() {
    this.props.actions.authenticateUser(false)
    .then(user => {
      let accessToken = this.props.user.signInUserSession.accessToken;
      let exp = accessToken.payload.exp;
      // console.log("OPENID USER NAME:" + this.props.user.username);
      let username = this.props.user.username;
      // let fed_response = Auth.federatedSignIn('openid', { accessToken, expires_at: exp }, { name: username })
    })
    .catch(error => {

      //alert("Error authenticating user: " + error);
      // console.log("Error authenticating user: " + error);
      window.location.assign(url)
    });
   }


  render() {
    return (
      
      <div className="container" id="root" >
            <Header application_title={properties.title}/>

            <br />


            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_Enhanced") ? <PowerBI />  : ''}
            <br />
            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <br /> : '' }
            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <ActivityMonitor />  : ''}
            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <br />  : ''}
            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <PortfolioFileUpload />  : ''}
            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <MainSearch />  : ''}
            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <PortfolioDatePicker />  : ''}
            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <PortfolioTable />  : ''}
            {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <CalcTable /> : ''}
            <br />

            { this.props.user ? 
            <Footer accucyb_version={version.accucyb_version} />
            : 'Trying to log you in...' }
        </div>

    )
  };
}

function mapStateToProps(state) {
  // debugger;
  return {
      user: state.user
  };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authActions, dispatch)
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(App);
/*
// This is my custom Sign in component
class MySignIn extends SignIn {
  render() {
    const { showPassword } = this.state;
    return (
      <div className="Auth-wrapper">


<MuiThemeProvider>
          <Card raised={false} className="opaque">
            <CardContent className="root">
              <Typography variant="display1" align="center" paragraph>
                Sign in
              </Typography>
              <FormControl className="formControl">
                <InputLabel htmlFor="username">Username</InputLabel>
                <Input
                  autoFocus
                  fullWidth
                  id="username"
                  label="Username"
                  inputProps={{
                    key: 'username',
                    name: 'username',
                  }}
                  onChange={this.handleInputChange}
                />
              </FormControl>
              <FormControl className="formControl">
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  fullWidth
                  id="password"
                  inputProps={{
                    key: 'password',
                    name: 'password',
                  }}
                  onChange={this.handleInputChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
                <FormHelperText>
                  Forgot your password?
                  {' '}
                  <ButtonBase onClick={this.forgotPasswordClick} className="inlineTextButton">
                    Reset
                  </ButtonBase>
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.signIn}
              >
                Sign In
              </Button>
            </CardContent>
          </Card>
        </MuiThemeProvider>


      </div>
    )
  }
}
*/
/*
export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticator(App, false, [
  <MySignIn/>
]
));
*/
/*
export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticator(App, {
  usernameAttributes: 'email'
}));
*/
//export default connect(mapStateToProps, mapDispatchToProps)(App);
/*
export default connect(mapStateToProps, mapDispatchToProps)(withAuthenticator(App, {
  usernameAttributes: 'email'
}));
*/
//export default withAuthenticator(App);

// {this.props.user.attributes.profile == "TOP_F_AccuCyb_Enhanced" ? "POWERBI" :  '' }

// {this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <PortfolioFileUpload />  : ''}

//{this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <PortfolioTable />  : ''}
//{this.props.user.attributes.profile.includes("TOP_F_AccuCyb_User") ? <CalcTable /> : ''}


/*

            { this.props.user ? 
            <center>
              <SignOut />
            </center>
            : '' }
*/