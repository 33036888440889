import * as action_types  from "./actionTypes";
import * as mainSearchApi from "../../api/mainSearchApi";
import * as startCalculationApi from "../../api/startCalculationsApi";

export function startCalcSuccess(calc_response) {
    // console.log("START CALC RESPONSE: " + calc_response);
    return { type: action_types.START_CALCULATIONS_SUCCESS, calc_response: calc_response }
}

export function startCalculation(idToken, portfolio_file_name) {
    return function (dispatch) {
        return startCalculationApi.startCalculation(idToken, portfolio_file_name).then(calc_response => {
            // console.log("API CALL START CALCULATION RESPONSE: " + JSON.stringify(calc_response))
            //debugger;
            dispatch(startCalcSuccess(calc_response));
        }).catch(error => {
            throw error;
        })
    }
}

export function loadMainSearchSuccess(search_response) {
    // console.log("SEARCH RESPONSE: " + search_response);
    return { type: action_types.GET_MAIN_SEARCH_SUCCESS, search_response: search_response }
}

export function getMainSearchData(idToken, query_str) {
    return function (dispatch) {
        return mainSearchApi.getMainSearchData(idToken, query_str).then(search_response => {
            // console.log("API CALL SEARCH RESPONSE: " + JSON.stringify(search_response))
            //debugger;
            dispatch(loadMainSearchSuccess(search_response));
        }).catch(error => {
            throw error;
        })
    }
}

export function calcFileDownloadSuccess(calc_download_data) {
    // console.log("CALC FILE DOWNLOAD RESPONSE: " + JSON.stringify(calc_download_data));
    return { type: action_types.CALC_FILE_DOWNLOAD_SUCCESS, calc_download_data: calc_download_data }
}

export function signedUriSuccess(calc_download_data, idToken, file_name) {

    // return { type: action_types.PORTFOLIO_UPLOAD_SUCCESS, portfolio_upload_data: portfolio_upload_data }
    return function (dispatch) {
        return mainSearchApi.handleCalcFileDownload(calc_download_data, idToken, file_name).then(calc_download_data => {
            // console.log("ERROR SIGNED URI RESPONSE: " + JSON.stringify(error_file_data));
            // debugger;
            dispatch(calcFileDownloadSuccess(calc_download_data));
        }).catch(error => {
            throw error;
        })
    }
}

export function handleMainSearchCalcDownload(idToken, file_name) {
    return function (dispatch) {
        return mainSearchApi.getMainSearchSignedDownloadUri(idToken, file_name).then(calc_download_data => {
            // console.log("API CALL SEARCH RESPONSE: " + JSON.stringify(search_response))
            //debugger;
            dispatch(signedUriSuccess(calc_download_data, idToken, file_name));
        }).catch(error => {
            throw error;
        })
    }
}

