import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as portfolioUploadActions from "../../redux/actions/portfolioUploadActions";


class PortfolioFileUpload extends React.Component {
    state = {
      upload_msg: "Upload idle..."
  }
  fileSelectedHandler = (event) => {
      const thisObj = this;
      // event.preventDefault();
      let file_selected = event.target.files[0];
      thisObj.setState({ upload_msg: "Upload started..." });
      // alert(file_selected);
      // console.log(file_selected);
      this.props.actions.portfolioUpload(this.props.user.signInUserSession.idToken.jwtToken, file_selected)
      .then(res => {
          thisObj.setState({ upload_msg: "Upload done..." });
      })
      .catch(error => {
          thisObj.setState({ upload_msg: "Upload error..." + error });
          alert("Error getting portfolio data: " + error);
      });
    // this.props.parentFileSelectedHandler(event);
  };
    render() {
      return (
        <div>
          <div>
            <h2>Portfolio Upload</h2>
            <label htmlFor="portfolioUpload">Select and Upload Portfolio:</label>
            <br />
            <input type="file" accept=".csv" name="portfolioUpload" onChange={this.fileSelectedHandler} />
            <br />
            <br />
          </div>
          <div>{this.state.upload_msg}</div>
          <br />
          <hr></hr>
        </div>
    )}
  };

  function mapStateToProps(state) {
    //debugger;
    // console.log("PORTFOLIO UPLOAD RESULT: ");
    // console.log(state.portfolio_upload_data);
    return {
        user: state.user,
        portfolio_upload_data: state.portfolio_upload_data
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(portfolioUploadActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioFileUpload);