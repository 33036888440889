import * as action_types  from "./actionTypes";
import * as authApi from "../../api/authApi";

export function authSuccess(user) {
    // console.log("AUTH RESPONSE: ");
    // console.log(user);
    return { type: action_types.AUTH_SUCCESS, user: user }
}
export function refreshSuccess(user) {
    //console.log("AUTH RESPONSE: ");
    // console.log(user);
    return { type: action_types.GET_JWT_SUCCESS, user: user }
}

export function authenticateUser(refresh_flag) {
    //return { type: action_types.AUTHENTICATE_USER }
    return function (dispatch) {
        return authApi.authenticateUserWithCognito(refresh_flag).then(user => {
            // debugger;
            if(refresh_flag) {
                dispatch(refreshSuccess(user));
            } else {
                dispatch(authSuccess(user));
            }
        }).catch(error => {
            throw error;
        })
    }  
}
