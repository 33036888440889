import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client'
import { properties } from '../../config.js';
import qs from 'qs'
// import getAccessToken from './authentication';

let accessToken = "";
const getAccessTokenUrl = properties.fe_content_api + "/fe/powerbi_embedded_auth";


class PowerBI extends React.Component {
    state = {
        accessToken: "XXX",
        embedUrl: '', 
        datasetId: '',
        reportId: '',
        error: [],
        access_token_expiration: ''
    }

    componentDidUpdate() {
        //console.log(this.props.user.signInUserSession.idToken.jwtToken);
        var token_exp_diff = new Date(new Date().getTime() + 20*60000).toISOString().toLocaleString('en-US', { timeZone: 'UTC' });
        //let now_time = new Date().toISOString().toLocaleString('de-DE', { timeZone: 'UTC' });
        console.log(this.state.access_token_expiration);
        console.log(token_exp_diff);

        if(new Date(token_exp_diff).getTime() > new Date(this.state.access_token_expiration).getTime() && this.state.accessToken !== 'XXX') {
            console.log('GET NEW TOKEN!!!');
            this.getAccessToken();
        }


        if (this.state.accessToken == 'XXX') {
            this.getAccessToken();
        }
    }
    updateClickHandler = () => {
        this.setState({ accessToken: 'YYY' });
        this.getAccessToken();
    };
    getAccessToken() {
        const thisObj = this;

        fetch(getAccessTokenUrl, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + this.props.user.signInUserSession.idToken.jwtToken,
                "Content-Type" : "application/json"
            }
        })
        .then((response) => {
            // console.log(response);
            response.json()
            .then(function (body) {
                if (response.ok) {
                    accessToken = body["access_token"];
                    let embedUrl = body["embedUrl"];
                    let reportId = body["id"];
                    let datasetId = body["datasetId"];
                    let access_token_expiration = body['access_token_expiration'].toLocaleString('de-DE', { timeZone: 'UTC' });

                    thisObj.setState({ reportId:reportId, accessToken: accessToken, embedUrl:embedUrl, datasetId:datasetId , access_token_expiration: access_token_expiration});
                    console.log(access_token_expiration);
                    // console.log("ACCESS TOKEN: " + accessToken);
                    // console.log("EMBED URL: " + embedUrl);
                    // console.log("REPORT ID: " + reportId);
                    // console.log("DATASET ID: " + datasetId);
                } else {
                    //errorMessage.push("Error " + response.status + ": " + body.error.code);
                    //thisObj.setState({ error: errorMessage });
                    console.log("Error " + response.status + ": " + body.error.code);
                }
            })
            .catch(function () {
                //errorMessage.push("Error " + response.status + ":  An error has occurred");
                //thisObj.setState({ error: errorMessage });
                console.log("Error " + response.status + ":  An error has occurred");
            });                    
        })
        .catch((error) => {
            console.log(error);
        });
    }
    render() {
        return (
            <div>
            <br />        
            <button className="btn" onClick={()=>{this.updateClickHandler()}} >(Re-)Load PowerBi</button>
            <br />        

            {this.state.reportId == '' ? '' : <PowerBIEmbed
            embedConfig = {{
                type: 'report',
                id: this.state.reportId,
                embedUrl: this.state.embedUrl,
                accessToken: this.state.accessToken,
                tokenType: models.TokenType.Embed,
                pageView: "fitToWidth",
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        }
                    },
                    //background: models.BackgroundType.Transparent,
                }
            }}

            eventHandlers = { 
                new Map([
                    ['loaded', function () {console.log('Report loaded');}],
                    ['rendered', function () {console.log('Report rendered');}],
                    ['error', function (event) {console.log(event.detail);}]
                ])
            }
                
            cssClassName = { "Embed-container" }

            getEmbeddedComponent = { (embeddedReport) => {
                window.report = embeddedReport;
            }}
        />
        }
        </div>


    )}
};


function mapStateToProps(state) {
    //debugger;
    // console.log("PORTFOLIO RESULT: ");
    // console.log(state.portfolio_data);
    return {
        user: state.user
    };
  }

export default connect(mapStateToProps)(PowerBI);
//export default PowerBI;


// disabled={this.state.accessToken === 'XXX'}


/*



            <>
                <div>ACCESS TOKEN:</div>
                <div>{this.state.accessToken}</div>
                <div>EMBEDD urlencoded:</div>
                <div>WWW: {this.state.embedUrl}</div>                
            </>



*/

/*

        <PowerBIEmbed
            embedConfig = {{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: this.state.datasetId,
                embedUrl: this.state.embedUrl,
                accessToken: this.state.accessToken,
                tokenType: models.TokenType.Embed,
                pageView: "fitToWidth",
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        }
                    },
                    //background: models.BackgroundType.Transparent,
                }
            }}

            eventHandlers = { 
                new Map([
                    ['loaded', function () {console.log('Report loaded');}],
                    ['rendered', function () {console.log('Report rendered');}],
                    ['error', function (event) {console.log(event.detail);}]
                ])
            }
                
            cssClassName = { "Embed-container" }

            getEmbeddedComponent = { (embeddedReport) => {
                window.report = embeddedReport;
            }}
        />

*/

/*    getAccessToken3() {
        let config = properties.power_bi_embedded;
        let authorityUrl = config.authorityUri.replace('common', config.tenantId);
    
        console.log("URL: " + authorityUrl);
    
        const thisObj = this;
        fetch(authorityUrl, {
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            },
            method: "GET",
            body: {
                grant_type: "client_credentials",
                client_id: config.clientId,
                client_secret: config.clientSecret,
                scope: config.scope
            }
        })
        .then(function (response) {
            //const errorMessage = [];
            //errorMessage.push("Error occurred while fetching the access token of the report")
            //errorMessage.push("Request Id: " + response.headers.get("requestId"));
            console.log("Error occurred while fetching the access token of the report");
            response.json()
            .then(function (body) {
                if (response.ok) {
                    accessToken = body["accessToken"];
                    console.log("ACCESS TOKEN: " + accessToken);
                    thisObj.setState({ accessToken: accessToken });
                }
                else {
                    //errorMessage.push("Error " + response.status + ": " + body.error.code);
                    //thisObj.setState({ error: errorMessage });
                    console.log("Error " + response.status + ": " + body.error.code);
                }
            })
            .catch(function () {
                //errorMessage.push("Error " + response.status + ":  An error has occurred");
                //thisObj.setState({ error: errorMessage });
                console.log("Error " + response.status + ":  An error has occurred");
            });
        })
        .catch(function (error) {
            //thisObj.setState({ error: error });
            console.log("Error " + error + ":  An error has occurred");
        })
    }
    



    */

    /*


            let config = properties.power_bi_embedded;
        let authorityUrl = config.authorityUri.replace('common', config.tenantId);
    
        console.log("URL: " + authorityUrl);
    
        const thisObj = this;
        fetch("https://login.microsoftonline.com/common/oauth2/token", {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            method: "POST",
            body: JSON.stringify({
                grant_type: "client_credentials",
                client_id: "2dcf5f50-712b-47e3-b913-cbfea9380992",
                client_secret: "5S8Lo4zSMubI5R._5h-SKW6J2~_A_vRIOy",
                scope: config.scope
            })
        })    
        .then((response) => {
            console.log(response);
        });

        */