import axios from 'axios';
import { handleResponse, handleError } from "./apiUtils";
import { properties } from '../config.js';

const getSignedUrl = properties.fe_content_api + "/fe/get_portfolio_uri";

export function handlePortfolioUpload(portfolio_upload_data, idToken, selected_file) {
    //console.log("SIGNED URI RESPONSE:" + JSON.stringify(portfolio_upload_data));
    // debugger;
    const fd = new FormData();
    // append the fields in presignedPostData in formData            
    Object.keys(portfolio_upload_data.fields).forEach(key => {
        fd.append(key, portfolio_upload_data.fields[key]);
    });
    const headers = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }
    fd.append('file', selected_file);
    //debugger;
    return axios.post(portfolio_upload_data.url, fd, headers)
                .then(handleResponse)
                .catch(handleError)
}

export function handleSignedUriResponse(idToken, selected_file) {
    // alert(idToken);
    // first get signed URI
    return fetch(getSignedUrl, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        },
        body:  JSON.stringify({
            "key_name": selected_file.name 
          })
    })
    // UPLOAD FILE HERE
    .then(handleResponse) // handleResponse
    .catch(handleError)
}