import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainSearchActions from "../../redux/actions/mainSearchActions";
import PropTypes from "prop-types";
import MaterialTable from 'material-table'

let calc_download_filename = '-';
let calc_download_etag = '-';

class MainSearch extends React.Component {
    state = {
        search_response: {
          results: [],
        }
    }

    onPressEnter = (event) => {
      if (event.key === "Enter") {
        // alert("You pressed enter!");
        this.handleSearchClick(event);
      }
    }    
    handleSearchClick = data => {
      let query_field = document.getElementById('main_query_field');
      let query_str = query_field.value;
      // alert(JSON.stringify(this.props));
      //this.props.dispatch(mainSearchActions.getMainSearchData());
      // alert(this.props.user.signInUserSession.idToken.jwtToken);
      // debugger;
      if(query_str.length >= 2) {
        this.props.actions.getMainSearchData(this.props.user.signInUserSession.idToken.jwtToken, query_str);
      } else {
        alert("The search string must have at least two characters!");
      }
    };

    startSearchResultClickHandler = (data) => {
      if(data.type == 'portfolio') {
        // alert(data.file_name);
        this.props.actions.startCalculation(this.props.user.signInUserSession.idToken.jwtToken, data.file_name);
        alert("Calculation Started!");
      }
      if(data.type == 'calculation') {
        // alert(data.file_name);
        calc_download_filename = data.file_name;
        this.props.actions.handleMainSearchCalcDownload(this.props.user.signInUserSession.idToken.jwtToken, calc_download_filename)
          .catch(error => {
            alert("Error getting calculation file data: " + error);
          });
      }    
    }    


    render() {
      return (

        <div>
          <div>
            <h2>AccuCyb Search</h2>
            <label htmlFor="portAccucybSearch">Enter Search String:</label>
            <br />
            <div><input id="main_query_field" type="text" placeholder="Enter search..." onKeyPress={this.onPressEnter} /> <button className="btn" onClick={this.handleSearchClick}>Start Search</button></div>
            <br />
            <br />
            <div style={{ maxWidth: '100%', display: this.props.search_response.results_size > 0 ? 'block' : 'none' }}>
            <MaterialTable
              columns={[
                { title: 'ID', field: '_id' },
                { title: 'User', field: 'name'},
                { title: 'Name', field: 'file_name'},
                { title: 'Creation Date', field: 'creation_date'},
                { title: 'Type', field: 'type' },
                { 
                  title: 'Status/Action',
                  field: 'status',
                  render: rowData =>  <button className="btn" onClick={()=>{this.startSearchResultClickHandler(rowData)}} disabled={rowData.status != 'finished'}>{rowData.type == 'portfolio' ? 'Start' : 'Download' }</button>
                  //rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}/>
                }
              ]}
              data={this.props.search_response.results}
              title="Search Results"
            />
          </div>            
            <br />
        </div>
        <hr></hr>
      </div>
    )}
};

MainSearch.propTypes = {
  search_response: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  //debugger;
  //console.log("SEARCH RESULT: ");
  //console.log(state.search_response);
  if (state.calc_download_data.headers['x-amz-request-id'] !== "" && state.calc_download_data.headers['x-amz-request-id'] !== calc_download_etag) {
    // console.log("x-amz-request-id: ");
    // console.log(state.error_file_data.headers['x-amz-request-id']);
    calc_download_etag = state.calc_download_data.headers['x-amz-request-id'];

    // create download link
    const csv_name_array = calc_download_filename.split("_-_")
    let csv_name = csv_name_array[0];
    if (csv_name_array.length > 1) {
      csv_name = 'calc_' + csv_name_array[1] + "_-_" + csv_name_array[2];
    }

    const element = document.createElement("a");
    const file = new Blob([state.calc_download_data.data],{type: 'text/csv;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = csv_name;
    document.body.appendChild(element);
    element.click();
  }

  return {
      user: state.user,
      search_response: state.search_response,
      calc_download_data: state.calc_download_data
  };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(mainSearchActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSearch);
