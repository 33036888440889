import { handleResponse, handleError } from "./apiUtils";
import { properties } from '../config.js';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
  } from 'react-day-picker/moment';

const baseUrl = properties.fe_content_api + "/fe/get_portfolio";

export function getPortfoliosData(idToken, day_query) {
    //alert(idToken);
    // alert(day_query);

    //const FORMAT = 'YYYY-MM-DD';
    //let format_day_query = formatDate(new Date(day_query), FORMAT);   
    //alert("API: " + format_day_query); 
    return fetch(baseUrl, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type" : "application/json"
        },
        body:  JSON.stringify({
            "day_query": day_query 
          })
    })
    .then(handleResponse)
    .catch(handleError)
}