import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function startCalculationReducer(state = initialStsate.calc_response, action) {
    switch (action.type) {
        case action_types.START_CALCULATIONS_SUCCESS:
            // console.log("CALC START ACTION: " + JSON.stringify(action.calc_response));
            //debugger;
            return action.calc_response; //[ ...state, {calc_response: action.calc_response }];
        default:
            return state;
    }
}