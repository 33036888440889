import * as action_types from "../actions/actionTypes";
import initialStsate from "./initialStsate";

export default function portfolioUploadReducer(state = initialStsate.portfolio_upload_data, action) {
    switch (action.type) {
        case action_types.PORTFOLIO_UPLOAD_SUCCESS:
            // console.log("PORTFOLIO UPLOAD REDUCER: " + JSON.stringify(action.portfolio_upload_data));
            //debugger;
            return action.portfolio_upload_data;
        default:
            return state;
    }
}