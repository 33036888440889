import { handleResponse, handleError } from "./apiUtils";
import Amplify, { API, Auth } from 'aws-amplify';
import { properties } from '../config.js';


let config_amp = Amplify.configure({
    Auth: {
        //storage: MyStorage,
        domain: properties.cognitoDomain,
        userPoolWebClientId: properties.appClientId,
        identityPoolId: properties.identityPoolId,
        userPoolId: properties.userPoolId,
        region: 'eu-central-1',
        // Authorized scopes
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        // Callback URL
        redirectSignIn: properties.redirectSignIn,
        // Sign out URL
        redirectSignOut: properties.redirectSignOut,
        responseType: 'code',
        options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true
        },
        oauth: {
        // Domain name
        domain: properties.cognitoDomain,
        // Authorized scopes
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        // Callback URL
        redirectSignIn: properties.redirectSignIn,
        // Sign out URL
        redirectSignOut: properties.redirectSignOut,
        responseType: 'code',
        options: {
            // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
            AdvancedSecurityDataCollectionFlag: true
        }
        }
    },
    API: {
        endpoints: [
            {
                name: "fe_content",
                endpoint: properties.fe_content,
                service: "lambda",
                region: "eu-central-1"
            },
            {
                name: "fe_content_api",
                endpoint: properties.fe_content_api,
                region: "eu-central-1"
            },
            {
            name: "json_test",
            endpoint: 'https://jsonplaceholder.typicode.com',
            region: "eu-central-1"
            }        

        ]
    },
    Storage: {
        AWSS3: {
            bucket: properties.bucket, //REQUIRED -  Amazon S3 bucket name
            region: "eu-central-1", //OPTIONAL -  Amazon service region
        }
    }  
    });

export function authenticateUserWithCognito(refresh_flag) {
    console.log(refresh_flag);
    if (refresh_flag == true ) {
        return Auth.currentSession()
        .then(handleResponse)
        .catch(handleError);

    } else {
        return Auth.currentAuthenticatedUser()
        .then(handleResponse)
        .catch(handleError);

    }
}
/*
export function getUserJwtFromCognito() {
    return Auth.currentAuthenticatedUser()
            .then(handleResponse)
            .catch(handleError);
}
*/